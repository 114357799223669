<template>
  <div class="chatbox-files">
    <div class="chatbox-files-main" v-if="filesPreview && filesPreview.length > 0">
      <PostMediaSlider class="media-visor-slider" :isEdit="true" :key="filesPreview.length" @delSlide="$emit('delSlide', $event)">
        <div v-for="(media, index) in filesPreview" :key="`media-${index}`" :class="`file creator-media-wrap`" tcenter>
          <img v-if="mediaType(media) === 'image'" :src="media.src" alt="Image" />
          <video v-else-if="mediaType(media) === 'video'" width="100%" controls playsinline controlslist="nodownload">
            <source :src="`${media.src}`" />
          </video>
        </div>
      </PostMediaSlider>
    </div>
    <div class="FilesPrice" v-if="$isCreator">
      <MessagesPriceBox />
    </div>
  </div>
</template>

<script>
export default {
  props: ["filesPreview"],
  components: {
    PostMediaSlider: () => import("../widgets/PostMediaSlider.vue"),
    MessagesPriceBox: () => import("./MessagesPriceBox.vue"),
  },
};
</script>

<style lang="scss">
$cbxh: var(--cbxh);
.chatbox-files {
  position: absolute;
  top: $header_height;
  right: 0;
  bottom: var(--cbxh);
  left: 0;
  background: #00000085;
  z-index: 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  &-main {
    display: flex;
    align-items: center;
    margin: auto 0 auto 0;
  }

  .media-visor-slider .slick-slide .creator-media-wrap {
    max-height: calc(100vh - #{$header_height} - #{$cbxh});
    max-width: 1200px;
    img,
    video {
      max-width: 100%;
    }
  }

  .FilesPrice {
    background-color: #fff;
    padding: 0 $mpadding $mpadding * 2 $mpadding;
  }

  .block-input input:not([type="checkbox"]):not([type="radio"]),
  .block-input textarea,
  .block-input [contenteditable] {
    text-align: center;
  }

  .FilesPrice .usdPriceLabel {
    pointer-events: none;
  }

  .inputFake .usdPriceLabel {
    pointer-events: none;
  }
}
</style>
